// https://github.com/aFarkas/lazysizes

// These styles are for the lazySizes.js library, that we use for handling lazyloading images.
img[data-sizes="auto"] {
	display: block;
	width: 100%;
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}

// .blur-up is useful to add on the image, if we're using a low quality image placeholder in the src and the fullres image in data-src
.blur-up {
	opacity: 1;
	filter: blur(10px);
	transition: filter 400ms;
}

.blur-up.lazyloaded {
	filter: blur(0);
}
