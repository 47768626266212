@use "sass:math";

.block-card {
	padding: $spacing-default * 1.5 $spacing-default * 2;
	display: flex;
	flex-direction: column;

	transition: box-shadow 0.2s ease-out;

	&:hover,
	&:focus {
		box-shadow: $box-shadow-blocks-hover;

		.arrow-link {
			color: var(--module-cta);
		}
	}

	&:focus-visible {
		outline: 1px solid var(--module-primary);
	}

	.arrow-link {
		margin-left: auto;
	}

	@include bp(sm) {
		padding: $spacing-default * 1.5 $spacing-default;
	}

	&__list {
		&-item {
			display: flex;
			gap: math.div($spacing-default, 2);

			&:not(:last-child) {
				margin-bottom: math.div($spacing-default, 2);
			}

			dt {
				font-weight: 800;
			}
		}
	}
}

.tag {
	border-bottom: 2px solid var(--module-primary);
	text-transform: uppercase;
	font-weight: bold;
	font-size: var(--font-uppercase-em);

	& + .tag {
		margin-left: math.div($spacing-default, 2);
	}

	& a {
		&:hover,
		&:focus-visible {
			color: $white;
			background-color: $color-blue-dark;
			border-radius: 2px;
		}
		&:focus-visible {
			outline: 1px solid var(--module-primary);
			outline-offset: 2px;
		}
	}

	&--alt {
		border-bottom: 0;

		&:not(:last-child) {
			position: relative;
			margin-right: math.div($spacing-default, 2);
			&::after {
				content: "";
				display: block;
				position: absolute;
				width: 2px;
				background-color: currentColor;
				right: math.div(-$spacing-default, 2);
				top: 2px;
				bottom: 2px;
			}
		}
	}
}

.pagination {
	ol {
		justify-content: center;
		align-items: center;

		li {
			.btn:not(.prev):not(.next) {
				// make the pagination numbers circles for the majority of numbers without breaking in case of large page numbers
				padding-left: 1.25em;
				padding-right: 1.25em;
				min-width: 41px;
				justify-content: center;
			}

			&:not(:last-child) {
				margin-right: math.div($spacing-default, 2);
			}
		}
	}
}

.figcaption {
	opacity: 0.78;
	font-size: 0.875em;
}

.news-search {
	position: relative;
	width: 100%;

	input {
		background-color: $white;
		width: 100%;
		border: 1px solid $grey-border;
		padding: 1em 3em 1em 3.5em;
		transition: box-shadow 0.15s ease-out;
		box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.12);

		&:focus {
			box-shadow: 0 0 0 2px rgba(darken($grey-border, 50), 0.25);
		}
	}
	button {
		padding: 0;
		border: 0;
		width: 1.5em;
		height: 1.5em;
		font-size: 1.7em;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		left: 0.35em;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 50%;
		background-color: transparent;

		svg {
			width: 24px;
		}
	}

	.loader {
		position: absolute;
		right: 0.9em;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}
}

.news-filters {
	padding-top: math.div($spacing-default, 2);
	padding-left: math.div($spacing-default, 2);
	padding-right: math.div($spacing-default, 2);
	box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.12);
	border-radius: $border-radius-base;
}

.news-pagecount {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	label {
		margin-bottom: 0;
		margin-right: math.div($spacing-default, 2);
	}

	select {
		width: auto !important;
	}
}

.news-date {
	display: flex;
	align-items: center;

	.divider {
		width: 20px;
		min-width: 20px;
		height: 2px;
		background-color: $grey-dark;
		margin: 0 math.div($spacing-default, 2);
		display: inline-block;
	}

	@include bp(sm) {
		input {
			max-width: calc(50% - 20px) !important; // 20px = divider width
		}
	}
}

.news-title {
	padding-left: math.div($spacing-default, 2);
}
