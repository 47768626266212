.campaign-box {
	&__inner {
		box-shadow: $box-shadow-base;
		background-color: var(--module-primary);
		color: var(--module-primary-text);
		@include bp(xs) {
			box-shadow: unset;
			background-color: transparent;
		}
	}
	&__media {
		min-height: $height-medium * 1.25;
		background: var(--module-primary);
		@include bp(xs) {
			min-height: auto;
			height: $height-medium * 0.85;
		}
	}
	&__link {
		display: flex;
		justify-content: flex-end;
		color: var(--module-primary-text);
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	&__content {
		background: var(--module-primary);
		padding: $spacing-large;
		
		@include bp(sm) {
			padding: $spacing-default * 1.5;
		}
		
		@include bp(xs) {
			margin-top: -($spacing-default * 3);
			width: calc(100% - $spacing-default);
			margin-left: $spacing-default;
			box-shadow: $box-shadow-base;
			&--iframe {
				margin-top: 0;
			}
		}
	}
	&__link-list-module {
		.link-list-module {
			padding: 0;
			&__box {
				background-color: inherit;
				box-shadow: none;
				color: inherit;
			}

			&__inner {
				padding: 0;
			}
		}
	}
	&__video {
		height: 0;
		padding-top: $ratio-16-9;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		iframe {
			position: absolute;
			inset: 0;
			
		}
		
		@include bp(xs) {
			top: auto;
			transform: none;
		}
	}

	&--iframe {
		.campaign-box {
			&__content {
				margin-top: 0;
				margin-left: 0;
				width: auto;
			}
			
			&__media {
				min-height: auto;
				height: auto;
			}
		}
	}

	&--right {
		.campaign-box {
			&__order {
				display: flex;
			}

			&__media {
				order: 2;

				@include bp(xs) {
					order: 1;
				}
			}

			&__order-text {
				order: 1;

				@include bp(xs) {
					order: 2;
				}
			}
		}
	}
}
