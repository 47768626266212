@use "sass:math";

.entrance-box-list {
	background-color: var(--module-background);

	&__title {
		color: var(--module-background-text);
	}

	&__list {
		li:not(:last-child) {
			margin-bottom: math.div($spacing-default, 2);
		}
	}

	&--contentpage {
		margin-inline: -$spacing-default * 2;
		padding-inline: $spacing-default * 2;
		@include bp(sm) {
			margin-inline: -$spacing-default;
			padding-inline: $spacing-default;
		}
	}
	&--with-bg {
		padding-block: $spacing-default;
		
		&.entrance-box-list--contentpage {
			padding-block: $spacing-default * 2;
			@include bp(sm) {
				padding-block: $spacing-default;
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $spacing-default * 1.5;
		transition: background-color 0.2s ease-out, color 0.2s ease-out;
		background-color: var(--module-primary);

		@media print {
			align-items: flex-start;
		}
		.title, .teaser {
			color: var(--module-primary-text);
			transition: background-color 0.2s ease-out, color 0.2s ease-out;
		}
		.icon {
			fill: var(--module-primary-text);
			margin-left: math.div($spacing-default, 2);
		}

		&:hover,
		&:focus {
			background-color: var(--module-cta-dark);
			
			.title, .teaser {
				color: var(--module-cta-text);
			}
			.icon {
				fill: var(--module-cta-text);
			}
		}

		@include bp(sm) {
			padding: $spacing-default;
		}
	}
}
