@use "sass:math";

.service-menu {
	grid-area: servicemenu;
	justify-self: end;

	@include bp($navigation-breakpoint) {
		padding: $spacing-default * 1.25;
	}

	&__list {
		display: flex;
		align-items: center;
		height: 100%;
		padding: $spacing-default * 0.75 0;
		@include bp($navigation-breakpoint) {
			display: block !important;
			width: 100%;
		}
	}

	&__item:not(:last-of-type) {
		margin-right: 1em;
		padding-right: 1em;
		border-right: 1px solid $grey-border;
		@include bp($navigation-breakpoint) {
			border-right: none;
		}
	}

	&__link {
		@include bp($navigation-breakpoint) {
			border: none;
			margin: 0;
			padding-bottom: math.div($spacing-default, 2);
			display: block;
		}
	}
}
