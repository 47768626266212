.entrance-box {
	display: flex;
	position: relative;

	&__link {
		color: var(--module-cta);
		justify-content: flex-end;
	}

	&__box {
		background-color: var(--module-primary);
		color: var(--module-primary-text);
		padding: $spacing-default * 2;
		box-shadow: $box-shadow-base;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		min-height: 300px;
		margin-top: auto;
	}

	&__content {
		flex: 1;
	}

	&__link-list-module {
		.link-list-module {
			padding: 0;
			&__box {
				background-color: inherit;
				box-shadow: none;
				color: inherit;
			}

			&__inner {
				padding: 0;
			}
		}
	}

	&__image {
		height: 0;
		
		@media print {
			display: none;
		}
		
		&-inner {
			position: absolute;
			top: 0;
			left: $spacing-default;
			bottom: $spacing-default;
			width: calc(100% - #{$spacing-default});
			background-color: $grey-dark;

			img {
				position: absolute;
				inset: 0;
			}
		}

		&--desktop {
			@include bp(sm) {
				display: none;
			}
		}

		&--mobile {
			display: none;

			@include bp(sm) {
				display: block;
			}
		}
	}
}

// positioning of the image and textbox
.entrance-box-module {
	&--two-over-three {
		.entrance-box {
			padding-top: 35%;

			&__image {
				width: 38%;
			}
			&__box {
				width: 62%;
			}
		}
	}
	&--one-over-three {
		.entrance-box {
			padding-top: 0;

			&__image {
				display: none;
			}
			&__box {
				width: 100%;
				min-height: auto;
				margin-top: initial;
			}
		}
	}
	&--two-equal-column {
		.entrance-box {
			padding-top: 35%;

			&__image {
				width: 17%;
			}
			&__box {
				width: 83%;
			}
		}
	}
	&--full-width {
		.entrance-box {
			padding-top: 23%;

			&__image {
				width: 59%;
			}
			&__box {
				width: 41%;
			}
		}
	}
}

// grid layouts
.entrance-box-module {
	height: 100%;
	.entrance-box {
		height: 100%;
	}

	&__layout-container {
		display: grid;
		gap: $spacing-default $spacing-default;
		grid-gap: $spacing-default $spacing-default;
		
		&--1 {
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			grid-template-areas: "gridblock1 gridblock1 gridblock2" "gridblock1 gridblock1 gridblock3";
		}
		&--2 {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr;
			grid-template-areas: "gridblock1 gridblock2";
		}
	}
	&__layout-block-1 {
		grid-area: gridblock1;
	}
	&__layout-block-2 {
		grid-area: gridblock2;
	}
	&__layout-block-3 {
		grid-area: gridblock3;
	}
	
}

// MOBILE
@mixin entranceBoxMobileLayout($bp) {
	@include bp($bp) {
		.entrance-box-module {
			.entrance-box {
				padding-top: 69%;
				@media print {
					padding-top: 0 !important;
				}
				&__box {
					padding: $spacing-default * 1.5;
					width: calc(100% - #{$spacing-default});
					min-height: 170px;			
					
				}
				&__image {
					width: 0;
		
					&-inner {
						left: 0;
						width: 100%;
						bottom: 107px;
					}
				}
			}
	
			&--one-over-three {
				.entrance-box {
					padding-top: 0;
					&__box {
						width: 100%;
						min-height: auto;
					}
				}
			}

			&__layout-container {
				display: block;
			}
		}

		[class*=entrance-box-module__layout-block]:not(:last-child){
			margin-bottom: $spacing-default;
		}
	}
}

@include entranceBoxMobileLayout(md);
// different breakpoints for when shown on contentpages (lg instead of md)
.entrance-box--contentpage {
	@include entranceBoxMobileLayout(lg);
}
.entrance-box-module--full-width-mobile {
	// make the module go to the edge of the screen on mobile
	@include bp(sm) {
		margin-left: -$spacing-default;
		margin-right: -$spacing-default;
	}
}