@use "sass:math";

.left-menu {
	background-color: $color-blue-dark;
	color: $white;
	padding-top: $spacing-default * 2;
	padding-bottom: $spacing-default * 2;
	height: 100%;

	&__headline {
		margin-bottom: $spacing-default * 1.25;
		font-weight: $font-weight;
		display: block;

		&:hover {
			text-decoration: underline;
		}
	}
	&__navigation-item {
		padding: math.div($spacing-default, 2) 0;
		button {
			display: none;
		}
		&--active {
			> div a {
				font-weight: $font-weight;
			}
		}
		&--expandable {
			> div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				button {
					display: flex;
					align-items: center;
					border: 1px solid $white;
					border-radius: 50%;
					margin-left: $spacing-default;
					min-width: $icon-normal;
					min-height: $icon-normal;
					position: relative;
					&:hover {
						border-color: transparent;
						background: $white;
						svg * {
							fill: $color-blue-dark;
						}
					}
				}
				svg {
					width: $icon-normal;
					height: $icon-normal;
					position: absolute;
					top: -1px;
					left: -1px;
					transform-origin: center;
					@include transition-all();
					* {
						fill: $white;
					}
				}
				.plus-icon {
					opacity: 1;
					transform: rotate(0deg);
				}
				.minus-icon {
					opacity: 0;
					transform: rotate(90deg);
				}
			}
			> ul {
				max-height: 0;
				visibility: hidden;
				opacity: 0;
				margin: 0;
				@include transition-all();
			}
		}
		&--expanded {
			padding-bottom: 0;
			> div {
				.plus-icon {
					opacity: 0;
					transform: rotate(90deg);
				}
				.minus-icon {
					opacity: 1;
					transform: rotate(0deg);
				}
			}
			> ul {
				max-height: 20000px;
				opacity: 1;
				visibility: visible;
				margin-top: math.div($spacing-default, 2);
			}
		}
	}
	&__navigation-link {
		word-break: break-word;
		@extend .font-sm;
	}
	ul {
		ul {
			margin-left: $spacing-default;
		}
	}
}
#leftMenuItem {
	display: none;
}
