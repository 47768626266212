@import "./variables";
$gl-gutter: $spacing-default;
$gl-gutter-vertical: $spacing-default;
$gl-mq-list: (
	lg: $site-max-width,
	//1440
	md: 64em,
	//1024
	sm: 48em,
	//768
	xs: 36em,
	//576
) !default;

@import "gridlex";

// Each .col has by default a bottom padding, including the .col elements on the lsat row in the grid.
// In some cases we want the bottom padding on the .col elements, but NOT have it on the final row of .col elements.
// Hence this class that negates the bottom padding on the .grid element itself.
// NB: is not intended to work with gridlex .grid-noGutter or .grid-noBottom, as those removes the bottom padding, and as such don't need any negating of the last row
.grid-no-final-row-bottom {
	margin-bottom: -$gl-gutter-vertical;
}