.scroll-to-top-wrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: $spacing-default * 3;
}

.scroll-to-top {
	height: $min-touch-target-size;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: $white;
	transition: color 0.2s ease-out;

	&:hover,
	&:focus {
		color: $white;
	}

	svg {
		width: 30px;
		height: 30px;
	}
}
