@use "sass:math";

html:focus-within {
	scroll-behavior: smooth;

	@media (prefers-reduced-motion) {
		scroll-behavior: auto;
	}
}

body {
	font-family: "Source Sans Pro", sans-serif;
	word-break: break-word;
	background-color: $grey-warm;
	&.body--hide {
		overflow-y: hidden;
	}
}
a,
button {
	&:hover,
	&:active,
	&:focus {
		cursor: pointer;
	}
}
.container {
	max-width: $site-max-width;
	margin: 0 auto;
	width: 100%;
	padding: 0 math.div($spacing-default, 2);

	&--padded {
		padding: 0 $spacing-default;
	}

	&--padded-big {
		padding: 0 $spacing-default * 2;

		@include bp(sm) {
			padding: 0 $spacing-default;
		}
	}
}
.section {
	padding: $spacing-default * 1.5 $spacing-default * 2;
	@include bp(sm) {
		padding: $spacing-default * 1.5 $spacing-default;
	}
}

img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

.zone {
	--zone-bg: var(--module-primary);
	background-color: var(--zone-bg);
	background-position: top center;
	background-repeat: no-repeat;
	padding-top: $spacing-default;
	padding-bottom: $spacing-default;

	&--padding {
		padding-top: $spacing-default * 1.5;
		padding-bottom: $spacing-default * 1.5;
	}
	&--noformat {
		background: none;
	}

	&--contentpage {
		margin-inline: -$spacing-default * 2;
		padding-inline: $spacing-default;
		@include bp(sm) {
			margin-inline: -$spacing-default;
			padding-inline: 0;
		}
	}

	&__title {
		color: var(--module-primary-text);
		margin-top: -$spacing-default/4;
	}
}
