$font-path: "~assets/fonts";
/* source-sans-pro-regular - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 400;
	src: local(""),
		url($font-path + "/source-sans-pro-v14-latin-regular.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url($font-path + "/source-sans-pro-v14-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-italic - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 400;
	src: local(""),
		url($font-path + "/source-sans-pro-v14-latin-italic.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url($font-path + "/source-sans-pro-v14-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 700;
	src: local(""),
		url($font-path + "/source-sans-pro-v14-latin-700.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url($font-path + "/source-sans-pro-v14-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700italic - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 700;
	src: local(""),
		url($font-path + "/source-sans-pro-v14-latin-700italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url($font-path + "/source-sans-pro-v14-latin-700italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-900 - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 900;
	src: local(""),
		url($font-path + "/source-sans-pro-v14-latin-900.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url($font-path + "/source-sans-pro-v14-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-900italic - latin */
@font-face {
	font-family: "Source Sans Pro";
	font-style: italic;
	font-weight: 900;
	src: local(""),
		url($font-path + "/source-sans-pro-v14-latin-900italic.woff2")
			format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url($font-path + "/source-sans-pro-v14-latin-900italic.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
