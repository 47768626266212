@use "sass:math";

.link-list-module {
	&--centered {
		margin: 0 auto;
	}
	&__box {
		background-color: var(--module-primary);
		color: var(--module-primary-text);
		box-shadow: $box-shadow-base;
	}
	&__tag {
		margin-bottom: math.div($spacing-default, 4);
	}
	&__media {
		height: $height-small;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__inner {
		padding: $spacing-default * 1.5 $spacing-default * 2;
		display: flex;
		flex-direction: column;
		@include bp(xs) {
			padding: $spacing-default * 1.5;
		}
	}
	&__links {
		&--style {
			> li {
				margin-left: $spacing-default * 0.75;
				&::before {
					content: "·";
					font-size: var(--font-sm);
					font-weight: bold;
					position: absolute;
					margin-left: -($spacing-default * 0.75);
				}
			}
			.arrow-link {
				&:before {
					display: none;
				}
				&--file-name {
					display: block;
				}
			}
		}
	}
	&__link {
		align-self: flex-end;
		@include bp(sm) {
			margin-bottom: $spacing-default;
		}
	}
}
