@use "sass:math";

.shortcuts-link-list {
	.arrow-link {
		@include bp(sm) {
			font-size: var(--font-xs);
			font-weight: normal;

			&::before {
				transform: scale(0.85);
				margin-right: 7px;
			}
		}
	}

	.grid-no-final-row-bottom {
		@include bp(sm) {
			margin-bottom: math.div(-$spacing-default, 2);
		}
	}

	.link-container {
		@include bp(sm) {
			padding-right: $spacing-default;
			padding-left: $spacing-default;
			.link-col {
				padding-bottom: math.div($spacing-default, 2);
				padding-left: math.div($spacing-default, 4);
				padding-right: math.div($spacing-default, 4);
			}
		}
		.btn {
			text-align: left;
			word-break: break-word;
		}
	}
}
