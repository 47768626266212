// FONTS
:root {
	--font-xs: 16px;
	--font-sm: 19px;
	--font-md: 24px;
	--font-lg: 28px;
	--font-xl: 37px;
	--font-xxl: 47px;
	--font-uppercase-em: 0.875em; // this is to make all-uppercase words have the same visual height as normal capitalization

	--font-h4: 22px;
    --font-accordion: 24px;
    --font-module: 26px;
    --font-h3: 26px;
	@include bp(sm) {
		--font-xs: 17px;
		--font-sm: 20px;
		--font-md: 23px;
		--font-lg: 26px;
		--font-xl: 30px;
		--font-xxl: 35px;

		--font-h4: 20px;
        --font-accordion: 20px;
        --font-module: 24px;
        --font-h3: 24px;
	}
}

.font {
	&-xs {
		font-size: var(--font-xs);
	}
	&-sm {
		font-size: var(--font-sm);
	}
	&-md {
		font-size: var(--font-md);
	}
	&-lg {
		font-size: var(--font-lg);
	}
	&-xl {
		font-size: var(--font-xl);
		line-height: 1.05;
	}
	&-xxl {
		font-size: var(--font-xxl);
		line-height: 1.15;
	}
	&-h3 {
        font-size: var(--font-h3);
    }
    &-h4 {
        font-size: var(--font-h4);
    }
    &-accordion {
        font-size: var(--font-accordion);
    }
    &-module {
        font-size: var(--font-module);
    }
}
