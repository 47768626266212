@use "sass:math";

.campaign-wide {
	overflow: hidden; // needed for the image that's 50vw

	&:not(.noformat) {
		background-color: var(--module-primary);
		color: var(--module-primary-text);
	}
	
	&__order {
		align-items: flex-start !important; // only way wo override this selector [class*="grid-"] :'(
	}
	
	&__order-media {
		align-self: center;
	}

	&__content {				
		background: var(--module-primary);
		padding: $spacing-default * 2 50px 0 0;
		
		@media print {
			padding: 0;
			margin-top: 0 !important;
		}
		
		@include bp(xs) {
			margin-top: -($spacing-default * 3);
			margin-bottom: $spacing-default * 1.5;
			width: calc(100% - $spacing-default);
			margin-left: $spacing-default;
			box-shadow: $box-shadow-base;
			padding: $spacing-default * 1.5;
			
			:last-of-type {
				margin-bottom: 0;
			}
		}
		
		.menu-links-list {
			li:not(:last-child) {
				margin-bottom: math.div($spacing-default, 2);
			}
		}
	}

	.image-col {
		margin-right: calc(
			-100vw / 2 + 100%
		); // make the image take up 50vw while placed inside a .container

		@include bp(sm) {
			margin: 0;
			margin-bottom: $spacing-default;
		}
	}

	&__video {
		height: 0; 
		padding-top: $ratio-16-9; 
		position: relative;

		iframe {
			position: absolute;
			inset: 0;
		}
	}

	.image-col-wrapper {
		&--padded {
			@include bp(sm) {
				order: -1;
	
				.image-col {
					margin: 0;
					margin-top: $spacing-default;
				}
			}	
		}

		@include bp(sm) {
			order: -1;

			.image-col {
				margin: 0;
				margin-bottom: $spacing-default;
			}
		}
	}
	
	&--iframe {
		.campaign-wide {
			&__content {
				margin-top: 0;
				margin-left: 0;
				width: auto;
			}
		}
	}

	&--left {
		.image-col {
			margin-right: 0;
			margin-left: calc(
							-100vw / 2 + 100%
			); // make the image take up 50vw while placed inside a .container

		}

		.campaign-wide {
			&__order {
				@include bp(sm) {
					display: flex;
					align-items: flex-start;
				}
			}

			&__order-media {
				order: 1;
			}

			&__order-text {
				order: 2;
			}

			&__content {
				padding-left: 50px;
			}
		}
	}
}
