//spacing
$spacing-default: 20px;
$spacing-large: 50px;

//height
$logo-small-height: 280px;

$height-large: 600px;
$height-medium: 400px;
$height-small: 225px;

//icons
$icon-small: 15px;
$icon-normal: 25px;
$icon-large: 30px;
$icon-arrow-right: "data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBvbHlnb24NCiAgICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LC00KSINCiAgICAgIHBvaW50cz0iNCAxMSA0IDEzIDE2LjE3IDEzIDEwLjU5IDE4LjU5IDEyIDIwIDIwIDEyIDEyIDQgMTAuNTkgNS40MSAxNi4xNyAxMSINCiAgLz4NCjwvc3ZnPg0K";
$icon-external: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUuNSAwLjVMNy41IDguNSIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik04LjUgMC41TDE1LjUgMC41TDE1LjUgNy41IiBzdHJva2U9IiMwMDAiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTQuNSAwLjVMMS41IDAuNUMwLjk0OCAwLjUgMC41IDAuOTQ4IDAuNSAxLjVMMC41IDE0LjVDMC41IDE1LjA1MiAwLjk0OCAxNS41IDEuNSAxNS41TDE0LjUgMTUuNUMxNS4wNTIgMTUuNSAxNS41IDE1LjA1MiAxNS41IDE0LjVWMTEuNSIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==";

//border-radius
$border-radius-base: 5px;
$border-radius-large: 30px;

$font-weight: bold;

//size
$min-touch-target-size: 48px;
$site-max-width: 90em;

// shadows
$box-shadow-base: rgba(0, 0, 0, 0.05) 0 0 10px;
$box-shadow-buttons: -7px 10px 18px -13px rgba(50, 50, 50, 0.35),
	7px 10px 18px -13px rgba(50, 50, 50, 0.35);
$box-shadow-blocks-hover: 0 0.5rem 1rem rgba(50, 50, 50, 0.15);

$navigation-breakpoint: md;
$header-height-responsive: 80px;

$ratio-16-9: 56.25%;