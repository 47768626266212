@use "sass:math";

$block: "accordion-list";

.#{$block} {
	&__toggle-all {
		margin-left: auto;
		display: block;
		margin-bottom: math.div($spacing-default, 2);

		&:hover,
		&:focus-visible {
			text-decoration: underline;
		}
	}

	&--themed {
		padding: $spacing-default;
		background-color: var(--module-primary);
		color: var(--module-primary-text);

		.#{$block}__toggle-all {
			color: var(--module-cta);
		}

		.accordion-item__title {
			color: var(--module-cta);
		}
	}
}

.accordion-item {
	border-top: 1px solid $grey-border;

	&__title {
		.icon {
			margin-left: $spacing-default;
			display: flex;
			align-items: center;

			svg {
				width: $icon-small;
				height: $icon-small;
				transition: transform 0.125s ease-in-out;
			}
		}
	}

	&__toggle {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: $spacing-default 0;

		&:hover,
		&:focus-visible {
			text-decoration: underline;
		}
		&:focus-visible {
			position: relative;
			outline: none;
			
			&::after {
				content: "";
				position: absolute;
				top: 10px;
				left: -5px;
				right: -5px;
				bottom: 10px;
				outline: 2px dashed currentColor;
				user-select: none;
				pointer-events: none;
			}
		}
	}

	&__content {
		transition: height 0.3s ease-in-out;
		overflow: hidden;
		height: 0;
		position: relative;
		
		.container {
			padding: 0; // remove container padding from inserted modules
		}
		.module-m,
		.module-m--top {
			margin-top: 0;
			margin-bottom: 0;
		}
		.contact {
			padding-left: $spacing-default;
			padding-right: $spacing-default;
			flex: none;
			flex-basis: 100%;
			max-width: 100%;
			box-sizing: border-box;
			padding: 0 math.div($spacing-default, 2) $spacing-default;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: $spacing-default;

			@include bp(sm) {
				// make sure there's enough room for both the icon and text, by positioning them above eachother
				.contact-column {
					flex-flow: row wrap;

					&__content {
						width: 100%;
						margin-top: math.div($spacing-default, 2);
					}
				}
			}
		}
		.text-box-module,
		.link-list-module {
			&__box {
				height: 100%;
			}
			&__inner {
				padding-left: $spacing-default;
				padding-right: $spacing-default;
			}
			&.noformat {
				.text-box-module__inner,
				.link-list-module__inner {
					padding-left: 0;
				}
			}
		}
		.image-gallery {
			flex-basis: 100%;
			box-sizing: border-box;
			padding: 0 math.div($gl-gutter, 2) $gl-gutter-vertical;
			max-width: 100%;

			&__headline {
				padding: 0;
			}

			.swiper-button-prev,
			.swiper-button-next {
				background-color: $grey;
			}
		}

		> * {
			// add spacing between inserted modules
			margin-bottom: $spacing-default;
		}

		.quote-container, .fact-container {
		    background-color: $grey;
		}
	}

	&--expanded {
		.accordion-item__title {
			.icon svg {
				transform: rotate(180deg);
			}
		}
	}
}
