@use "sass:math";

.search-page {
	.search-form__input {
		border-radius: $border-radius-base;
	}
}

.cludo-sr-only {
	// @include screen-reader-text;
	@extend .sr-only;
}
.powered-by-cludo {
	display: none;
}

#facet-list {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;

	.facet-list-item {
		margin-right: math.div($spacing-default, 2);
		margin-bottom: math.div($spacing-default, 2);

		a {
			@extend .btn;
			@extend .btn--cta;
			@extend .btn--outline;
			// @extend .btn--rounded;
			padding: 0.475em 0.75em;
			border-radius: $border-radius-base;
			text-align: left;

			&.active {
				background-color: var(--module-cta);
				color: var(--module-cta-text);
			}
		}
	}
}

.search-result-count,
.search-did-you-mean {
	b,
	a {
		font-weight: bold;
	}
	a {
		text-decoration: underline;
	}
}

.cludo-banner {
	@extend .content-rte;
	@extend .block-card;
	background-color: var(--module-cta);
	color: var(--module-cta-text);
	margin-bottom: $spacing-default;

	a {
		color: inherit !important;
	}
}

#cludo-loading {
	display: flex;
	justify-content: center;
}

// PAGINATION
.cludo-page-navigation {
	margin-top: $spacing-default;

	.search_page_list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: row wrap;

		li {
			// hacky way of hiding cludo's < > icons for navigation and using our own text
			&.previous,
			&.next {
				a {
					color: transparent;
					&::before {
						content: "Forrige ";
						color: var(--module-cta);
						margin-right: -9px;
					}
					&:hover,
					&:focus {
						&::before {
							color: var(--module-cta-text);
						}
					}
				}
			}
			&.next a::before {
				content: "Næste ";
			}

			&:not(:last-child) {
				margin-right: math.div($spacing-default, 2);
			}

			&.active,
			a {
				@extend .btn;
				@extend .btn--cta;
				@extend .btn--rounded;
				// make the pagination numbers circles for the majority of numbers without breaking in case of large page numbers
				padding-left: 1.25em;
				padding-right: 1.25em;
				justify-content: center;
				// min-width: 41px;
			}

			&.active {
				pointer-events: none;
			}
			a {
				@extend .btn--outline;
			}

			@include bp(sm) {
				&:not(.previous):not(.next):not(.active) {
					display: none; // there's not enough room on mobile to show all of pagination
				}
			}
		}
	}
}

// SEARCH RESULTS
.search-results-item a {
	@extend .block-card;
	@extend .bg-white;
	@extend .mb;
	align-items: flex-start;

	h2 {
		@extend .font-md;
		@extend .font-bold;
		@extend .mb;
	}

	b {
		font-weight: bold;
	}

	.path {
		margin-top: $spacing-default;
		font-style: italic;
		@extend .figcaption;
	}
	.file-type {
		$cludo-icon-size: 15px;

		order: -1;
		@extend .tag;
		@extend .mb;
		border-bottom: 0;
		padding-left: $cludo-icon-size + 5px;
		position: relative;

		&::before {
			display: inline-block;
			position: absolute;
			top: 2px;
			left: 0;
			width: $cludo-icon-size;
			height: $cludo-icon-size;
			content: "";
			background-image: url(https://customer.cludo.com/img/cludo-search-icons.png);
			background-repeat: no-repeat;
			background-size: $cludo-icon-size * 2;
		}
		&.adobepdf {
			// color: #f91d0a;
			&::before {
				background-position: 0 0;
			}
		}
		&.microsoftword {
			// color: #335599;
			&::before {
				background-position: (-$cludo-icon-size) (-$cludo-icon-size);
			}
		}
		&.microsoftexcel {
			// color: #207245;
			&::before {
				background-position: (-$cludo-icon-size) 0;
			}
		}
		&.microsoftpowerpoint {
			// color: #d24625;
			&::before {
				background-position: 0 (-$cludo-icon-size);
			}
		}
	}

	&:hover,
	&:focus {
		h2 {
			text-decoration: underline;
		}
	}
}
