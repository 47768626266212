.tab-menu {
	grid-area: tabmenu;
	&__list {
		display: flex;
	}
	&__item {
		padding: $spacing-default * 0.8 0;
		margin-bottom: -1px;
		margin-right: -1px;
		@include bp($navigation-breakpoint) {
			padding: 0;
			background: $grey;
			width: 100%;
		}

		&:not(:last-child) {
			.tab-menu__link {
		       border-right: 1px solid $white;
			   @include bp($navigation-breakpoint) {
					border-right: none;
				}
			}
		}

		&.active {
			background-color: $white;
			color: $black;
		}
	}
	&__link {
		display: block;
		margin-bottom: -1px;
		padding: 0 $spacing-default * 2;
		@include bp($navigation-breakpoint) {
			border-bottom: 1px solid $grey-border;
			padding: $spacing-default * 0.8 $spacing-default * 1.25;
			height: 100%;
		}
		&.active {
			font-weight: bold;
			background-color: $white;
			color: $black;
		}
	}
}
