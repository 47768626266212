@use "sass:math";

.content-rte {
	strong {
		font-weight: bold;
	}
	em {
		font-style: italic;
	}

	a:not(.link-button):not(.link-button-internal) {
		@include content-link;
	}

	p,
	h2,
	h3,
	h4,
	h5,
	h6,
	ul,
	ol,
	table,
	iframe {
		margin-bottom: math.div($spacing-default, 2);
		max-width: 100%;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: $spacing-default * 2;
	}

	sub,
	sup {
		position: relative;
		font-size: 75%;
		line-height: 0;
		vertical-align: baseline;
	}
	sub {
		bottom: -0.25em;
	}
	sup {
		top: -0.5em;
	}
	table {
		padding: 0;
		max-width: 100%;
		background-color: transparent;
		border-collapse: collapse;
		border-spacing: 0;

		tbody {
			display: table-row-group;
			vertical-align: middle;
			border-color: inherit;
		}
		tr {
			display: table-row;
			vertical-align: inherit;
			border-color: inherit;
		}
		td,
		th {
			border: 1px solid currentColor;
			padding: math.div($spacing-default, 2);
		}
	}
	h2 {
		@extend .font-xl;
		@extend .font-bold;
	}
	h3 {
		@extend .font-h3;
		@extend .font-bold;
	}
	h4 {
		@extend .font-h4;
		@extend .font-bold;
	}
	h5 {
		@extend .font-sm;
		@extend .font-bold;
	}
	h6 {
		@extend .font-xs;
		@extend .font-bold;
	}

	ul,
	ol {
		list-style-type: revert;
		padding-inline-start: $spacing-default * 1.5;

		ul,
		ol {
			margin-bottom: 0;
		}
	}

	blockquote {
		padding: 0 0 0 $spacing-default;
		margin: 0 0 $spacing-default;
		font-style: italic;
		text-align: center;
		@extend .font-md;
		border-left: 0;
	}

	q {
		font-style: normal;
		display: inline;

		&::before {
			content: '';
		}
		&::after {
			content: '';
		}
	}

	.link-button {
		// padding: 20px 30px;
		margin-top: $spacing-default * 1.5;
		margin-right: $spacing-default;
		@extend .btn;
		@extend .btn--shadow;
		@extend .btn--rounded;
		@extend .btn--external;
		@extend .btn--cta;
		
		@media print {
			padding: 0;
		}
	}

	.link-button-internal {
		// padding: 20px 30px;
		margin-top: $spacing-default * 1.5;
		margin-right: $spacing-default;
		@extend .btn;
		@extend .btn--shadow;
		@extend .btn--rounded;
		@extend .btn--cta;
		@extend .arrow-link;
	}

	.imagecaption {
		@extend .figcaption;
		margin-top: -5px;
		display: block;
	}

	.trompet {
		// similar to .tag
		text-transform: uppercase;
		font-weight: bold;
		font-size: var(--font-uppercase-em);
	}

	.highlight {
		display: inline-block;
		margin-bottom: math.div($spacing-default, 2);
		@extend .font-xxl;
		@extend .font-bold;
	}

	.highlightNumber {
		font-size: 5rem;
		line-height: 100%;
		padding: $spacing-default;
		text-align: center;
		border: 1px solid var(--module-cta);
		min-width: 186px;
		min-height: 186px;
		border-radius: 200px;
		font-style: normal;
		font-weight: normal;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
	
	&--small {

		h2 {
			font-size: var(--font-sm);
		}
		h3 {
			font-size: var(--font-sm);
		}
		h4 {
			font-size: var(--font-xs);
		}
		h5 {
			font-size: var(--font-xs);
		}
		h6 {
			font-size: var(--font-xs);
		}
	}
}
