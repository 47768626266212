/*** The new CSS Reset - version 1.3.1 (last updated 28.10.2021) ***/
/* https://github.com/elad2412/the-new-css-reset */
/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(iframe, canvas, img, svg, [contenteditable="true"], video):not(svg
			*, symbol *)) {
	all: unset;
	display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove list styles (bullets/numbers) */
ol,
ul {
	list-style: none;
}

/* For images to not be able to exceed their container */
img {
	max-width: 100%;
	overflow: hidden; // for broken images to not overflow their container
}

/* removes spacing between cells in tables */
table {
	border-collapse: collapse;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
	white-space: revert;
}

*:focus-visible {
	outline: 2px dashed currentColor;
	outline-offset: -2px;
}
