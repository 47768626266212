﻿.cookies-button {
  color: var(--module-cta-text) !important;
  border: none !important;
  font-size: 1em !important;
  
  &:hover, &:focus {
    background-color: var(--module-cta-text) !important;
    color: $black !important;
  }
}

.ot-sdk-cookie-policy {
  h3 {
    color: unset !important;
    font-size: var(--font-md) !important;
    font-weight: bold !important;
  }
  
  h4, div, p, table, th, td, span, a {
    color: unset !important;
    font-size: unset !important;
  }

  a {
    background: none !important;
  }
}