.news-module {
	background-color: var(--module-background, transparent);
	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: $spacing-default;
		margin-top: -$spacing-default/5;

		h2,
		a {
			color: var(--module-background-text);
		}

		.arrow-link {
			margin-left: $spacing-default;
		}
	}

	&__list {
		@include bp(sm) {
			margin-inline: -$spacing-default * 0.5;
			padding-left: $spacing-default * 0.5;
		}
		.news-col {
			// preserve a minimum aspect ratio of the card, but allow it to grow if content is too big
			&::before {
				padding-bottom: 100%;
				content: "";
				float: left;
			}
			&::after {
				display: table;
				content: "";
				clear: both;
			}
		}

		.news-card {
			border-top: $spacing-default * 0.4 solid var(--module-primary);
			padding: $spacing-default * 1.5 $spacing-default * 2;
			display: flex;
			flex-direction: column;

			transition: background-color 0.3s ease-out, color 0.3s ease-out;

			&:hover,
			&:focus {
				background-color: var(--module-primary);
				color: var(--module-primary-text);
			}

			.arrow-link {
				margin-left: auto;
				margin-top: auto;
			}

			@include bp(sm) {
				padding: $spacing-default * 1.5 $spacing-default;
			}

			&__teaser {
				line-height: 1.5;
			}
		}

		.news-grid {
			@include bp(sm) {
				overflow-x: auto;
				flex-flow: row nowrap;
				scroll-snap-type: x mandatory;

				.news-col {
					scroll-snap-align: start;
				}
			}
		}
	}
}
