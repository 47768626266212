.nav-menu {
	grid-area: mainmenu;
	justify-self: end;
	display: flex;
	align-items: center;
	height: 100%;
	position: relative;
	@include bp($navigation-breakpoint) {
		padding: $spacing-default * 1.25;
	}
	&__list {
		display: flex;
		align-items: center;
		height: 100%;
		@include bp($navigation-breakpoint) {
			display: block;
			width: 100%;
		}

		&--search-disabled {
			li:last-child {
				margin-right: 0;
				padding-right: 0;
				border-right: none;
			}
		}
	}
	&__item {
		margin-right: $spacing-default * 0.8;
		padding-right: $spacing-default * 0.8;
		border-right: 1px solid $grey-border;

		@include bp($navigation-breakpoint) {
			border: none;
			margin: 0;
			padding: $spacing-default * 0.75 0;
			border-bottom: 1px solid $grey-border;
			display: flex;
			justify-content: space-between;
		}
		.icon {
			align-self: end;
		}
	}
	&__link {
		&--active {
			font-weight: bold;
		}
	}
}
