// styles for the page when printed

.show-on-print-only {
	display: none;
}

@media print {
	// show the link urls after the link text on new line
	a {
		display: flex;
		flex-direction: column;
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}
	
	//a[href]:after {
	//	content: " (" attr(href) ")";
	//	display: block;
	//	font-size: 0.8rem;
	//	font-weight: normal;
	//	vertical-align: middle;
	//	text-decoration: underline;
	//	text-transform: none;
	//}
	// Don't show links that are fragment identifiers,
	// or use the `javascript:` pseudo protocol
	a[href^="#"]::after,
	a[href^="javascript:"]::after {
		content: "";
	}

	// reemove solid background colors to save ink
	*,
	*::before,
	*::after {
		color: $black !important; // Black prints faster
		background-color: transparent !important;
		box-shadow: none !important;
	}

	body {
		background-color: transparent !important;
	}

	.show-on-print, .show-on-print-only {
		display: block !important;
	}

	.hide-on-print {
		display: none !important;
	}
	
	.print-border {
		border: 1px solid $black !important;
		margin-bottom: 20px !important;
		
		&--dimmed {
			border-color: $grey-border !important;
		}
	}
	
	.print-align-left, :before, :after {
		text-align: left !important;
		justify-content: flex-start !important;
		
		> *, > *:before, > *:after {
			text-align: left !important;
			justify-content: flex-start !important;
		}
	}
	
	.print-padding-x {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
	
	.print-padding-y {
		padding-top: 20px !important;
		padding-bottom: 20px !important;
	}
	
	.print-flex {
		display: flex !important;
	}
	.header__large, .header__small {
		border-bottom: none;
	}
}
