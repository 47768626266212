@use "sass:math";

// all form fields in content area. E.g. newslist, joblist, and forms created in sitecore
main {
	.form-label {
		@extend .text-uppercase;
		@extend .font-bold;
		display: inline-block;
		max-width: 100%;
		margin-bottom: math.div($spacing-default, 4);
	}
	input[type="text"],
	input[type="email"],
	input[type="search"],
	input[type="number"],
	input[type="password"],
	input[type="date"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="time"],
	input[type="week"],
	input[type="url"],
	input[type="file"],
	input[type="tel"],
	select,
	textarea {
		&:not(.input-no-default-style) {
			padding: math.div($spacing-default, 2) + 2 math.div($spacing-default, 2);
			width: 100%;
			max-width: 100%;
			min-height: 50px;
			vertical-align: middle;
			border: 1px solid $grey-border;
			background-color: $white;
			outline: 0;
			transition: box-shadow 0.15s ease-out;
			border-radius: $border-radius-base;
			box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.12);

			&:focus {
				box-shadow: 0 0 0 2px rgba(darken($grey-border, 50), 0.25);
			}

			&::placeholder {
				color: $grey-dark;
			}

			&:disabled,
			&.disabled {
				pointer-events: none;
				opacity: 0.78;

				&::file-selector-button {
					background-color: $grey-dark;
					color: inherit;
				}
			}
		}

		&.rounded {
			border-radius: 100px;
			padding-left: $spacing-default;
			padding-right: $spacing-default;
		}
	}

	input[type="file"]::file-selector-button {
		cursor: pointer;
		border: 0;
		padding: 0.475em 1.15em;
		border-radius: $border-radius-base;
		background-color: var(--module-cta);
		color: var(--module-cta-text);
		transition: 0.2s ease-out;
		font-family: inherit;
		margin-right: math.div($spacing-default, 2);
		&:hover {
			background-color: var(--module-cta-dark);
		}
	}

	select {
		&:not(.input-no-default-style) {
			$select-arrow-color: "000000";
			cursor: pointer;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23#{$select-arrow-color}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
			background-repeat: no-repeat;
			background-position: right 0.7em top 50%;
			background-size: 0.65em auto;
			padding-right: 1.8em;
		}
	}

	textarea {
		&:not(.input-no-default-style) {
			resize: vertical;
			min-height: 5rem;
		}
	}

	input[type="radio"],
	input[type="checkbox"] {
		&:not(.input-no-default-style) {
			height: 24px;
			width: 24px;
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;
			appearance: none;
			border: 1px solid $grey-border;
			border-radius: 0;
			outline: none;
			transition-duration: 0.3s;
			background-color: $white;
			cursor: pointer;
			display: inline-block;
			position: relative;
			transform: translateY(6px);
			margin-right: 6px;
			vertical-align: baseline;
			margin-bottom: 0;
			margin-left: 0;
			margin-top: 0;
			box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.12);

			&:focus {
				box-shadow: 0 0 0 2px rgba(darken($grey-border, 90), 0.25);
			}

			&:checked {
				border-color: var(--primary);
				background-color: var(--primary);
			}

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: transparent;
			}

			&::after {
				display: none;
			}

			&:disabled,
			&.disabled {
				pointer-events: none;
				opacity: 0.78;

				&:checked {
					opacity: 0.58;
				}
			}
		}
	}

	input[type="checkbox"] {
		&:not(.input-no-default-style) {
			&:checked::before {
				width: 8px;
				height: 16px;
				border-right: 2px solid var(--primary-text);
				border-bottom: 2px solid var(--primary-text);
				transform: translate(-50%, -63%) rotate(45deg);
			}
		}
	}
	input[type="radio"] {
		&:not(.input-no-default-style) {
			border-radius: 50%;

			&:checked::before {
				width: 8px;
				height: 8px;
				background-color: var(--primary-text);
				border-radius: 50%;
			}
		}
	}
}
