// K8
.column tbody tr {
	display: flex;
	flex-direction: column;
}

// K14, K15
.Faktaboksoverskrift,
.AdresseFed,
.AfdelingsnavnFremhvet {
	font-weight: bold;
}

// K15
.AfdelingsnavnFremhvet {
	font-size: var(--font-sm);
}

// K15
.Funktion {
	text-decoration: underline;
}

// K16
.Billedtekst {
	font-size: var(--font-xs);
	font-weight: bold;
	margin-top: -$spacing-default;
}

// K30
.Tabel-Gitter,
.Tabelgitter-lys,
.Blank {
	img {
		max-width: initial;
	}
}