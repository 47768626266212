@use "sass:math";

.image-gallery {
	&__headline {
		@include bp(xs) {
			padding: 0 $spacing-default;
		}
	}
	&__swiper {
		width: 100%;
		padding-bottom: $spacing-large !important;
	}
	&__swiper-slide {
		box-shadow: $box-shadow-base;
		height: auto !important;

	}
	&__count {
		position: absolute;
		right: $spacing-default;
		bottom: 0 !important;
		max-width: calc(100% - 155px);
		transform: translateY(100%);
		height: 50px;
		align-items: center;
		display: flex;
	}

	&-wrapper {
		width: 100%;
	}

	.fact-container {
		background-color: $white;
		padding-top: math.div($spacing-default, 2);
		padding-left: 58px;
		padding-right: 58px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.content-rte.pb-2 {
			padding-bottom: 0;
		}
	}
	.quote-container {
		background-color: $white;
	}

	//override swiper style start
	&__swiper-pagination {
		bottom: 0 !important;
		display: flex;
		left: 0 !important;
		width: 150px !important;
		padding-left: 25px;
		height: 50px;
		align-items: center;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: var(--zone-bg);
			z-index: -1;
			-webkit-mask-image: linear-gradient(
				90deg,
				rgba(0, 0, 0, 1) 70%,
				transparent
			);
			mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 70%, transparent);
		}
	}
	&__swiper-scrollbar {
		height: math.div($spacing-default, 2) !important;
		width: 100% !important;
		left: 0 !important;
		// top: calc(90% - (math.div($spacing-default, 2)) !important;
		bottom: $spacing-large !important;
		background: var(--module-cta) !important;
		border-radius: unset !important;
		.swiper-scrollbar-drag {
			border-radius: unset !important;
			&::before,
			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				content: "";
			}
			&:after {
				background-color: $grey-warm;
				width: 100vw;
				z-index: -1;
			}
			&:before {
				background-color: var(--module-cta);
				z-index: 1;
			}
		}
	}
	//override swiper style end

	.swiper-button-prev,
	.swiper-button-next {
		position: absolute;
		top: calc(50% - #{$spacing-large});
		height: 48px;
		width: 48px;
		background-color: $white;
		z-index: 1;
		cursor: pointer;
		box-shadow: $box-shadow-base;
		background-image: url(#{$icon-arrow-right});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: $icon-small;
		transition: background-color 0.15s ease-in-out;

		&:hover,
		&:focus-visible {
			background-color: $grey;
		}
	}
	.swiper-button-next {
		right: 0;
	}
	.swiper-button-prev {
		transform: rotate(180deg);
		left: 0;
	}
	.swiper-button-disabled {
		opacity: 0.6;
		cursor: auto;
		pointer-events: none;
	}
}
