@use "sass:math";
@import "./sign-language-helper";
@import "./scroll-to-top";

.main-footer {
	border-top: $spacing-default * 0.4 solid $color-blue-dark;
	word-break: break-word;

	&__content {
		padding-top: $spacing-default * 3;
		padding-bottom: $spacing-default * 4;

		a:not(.footer-heading) {
			font-size: var(--font-xs);
		}

		@include bp(sm) {
			padding-top: $spacing-default * 2;
			padding-bottom: $spacing-default * 1.5;
		}

		.footer-link-list {
			margin-bottom: $spacing-default * 1.25;

			li:not(:last-child) .arrow-link {
				margin-bottom: math.div($spacing-default, 2);
			}
		}

		.footer-logo {
			max-width: 280px;
		}
		.content-rte a {
			color: inherit !important;
		}
	}

	&__footer {
		background-color: $color-blue-dark;
		color: $white;
	}

	&__contact {
		padding-top: $spacing-default * 1.5;

		@include bp(sm) {
			padding-top: $spacing-default;
		}
	}
}
