@use "sass:math";

.logo {
	grid-area: logo;
	margin-right: $spacing-default * 1.5;

	&__container {
		display: flex;
		align-items: center;
		min-width: 300px;
		max-width: 470px;
		width: auto;
		height: 100%;
	}

	img {
		max-height: 3.4rem;
		object-fit: contain;
		height: 100%;
		margin: 3px 0;
	}

	&__name {
		font-weight: bold;
		margin-left: $spacing-default * 0.75;
		position: relative;
		max-width: 380px;
		line-height: 1.2;
	}

	&__line {
		margin-left: $spacing-default * 0.8;
		width: 1px;
		min-height: 3.7rem;
		background-color: $black;
	}

	a {
		margin-top: $spacing-default * 0.75;
		margin-bottom: $spacing-default * 0.75;
		display: block;
	}
	@include bp($navigation-breakpoint) {
		a {
			margin: 0;
		}
		img {
			width: $logo-small-height;
		}
	}
	&__print {
		img {
			max-height: 100%;
			width: auto;
			max-width: 500px;
			height: 100%;
			padding-left: $spacing-default;
		}
	}
}
