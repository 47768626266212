@use "sass:math";

.ul--nostyling {
	list-style-type: none;
	padding-left: 0;
	margin-top: 0;
	> li:before {
		content: none !important;
	}
}

.font-bold {
	font-weight: bold;
}
.text-uppercase {
	text-transform: uppercase;
	font-size: var(--font-uppercase-em);
}
.text-max-width {
	max-width: 778px; // we don't want lines of text to be too long. 778px is also equal to 9 columns in our grid
}
.color {
	&-white {
		color: $white;
	}
	&-black {
		color: $black;
	}
}

.d {
	&-none {
		display: none;
	}

	&-block {
		display: block;
	}

	&-flex {
		display: flex;
	}

	&-sm-none {
		@include bp(sm) {
			display: none;
		}
	}
	&-sm-block {
		@include bp(sm) {
			display: block;
		}
	}
	&-sm-flex {
		@include bp(sm) {
			display: flex;
		}
	}
}

.mb {
	margin-bottom: math.div($spacing-default, 2);

	&-0 {
		margin-bottom: 0;
	}
	&-1 {
		margin-bottom: $spacing-default;
	}
	&-2 {
		margin-bottom: $spacing-default * 2;
	}

	@include bp(sm) {
		&-sm {
			margin-bottom: math.div($spacing-default, 2);

			&-0 {
				margin-bottom: 0;
			}
			&-1 {
				margin-bottom: $spacing-default;
			}
			&-2 {
				margin-bottom: $spacing-default * 2;
			}
		}
	}
}

.ml {
	margin-left: math.div($spacing-default, 2);

	&-0 {
		margin-left: 0;
	}
	&-1 {
		margin-left: $spacing-default;
	}
	&-2 {
		margin-left: $spacing-default * 2;
	}

	@include bp(sm) {
		&-sm {
			margin-left: math.div($spacing-default, 2);

			&-0 {
				margin-left: 0;
			}
			&-1 {
				margin-left: $spacing-default;
			}
			&-2 {
				margin-left: $spacing-default * 2;
			}
		}
	}
}

.mt {
	margin-top: math.div($spacing-default, 2);

	&-0 {
		margin-top: 0;
	}
	&-1 {
		margin-top: $spacing-default;
	}
	&-2 {
		margin-top: $spacing-default * 2;
	}

	@include bp(sm) {
		&-sm {
			margin-top: math.div($spacing-default, 2);

			&-0 {
				margin-top: 0;
			}
			&-1 {
				margin-top: $spacing-default;
			}
			&-2 {
				margin-top: $spacing-default * 2;
			}
		}
	}
}
.pb {
	padding-bottom: math.div($spacing-default, 2);

	&-0 {
		padding-bottom: 0;
	}
	&-1 {
		padding-bottom: $spacing-default;
	}
	&-2 {
		padding-bottom: $spacing-default * 2;
	}
	&-1_5 {
		padding-bottom: $spacing-default * 1.5;
	}

	@include bp(sm) {
		&-sm {
			padding-bottom: math.div($spacing-default, 2);

			&-0 {
				padding-bottom: 0;
			}
			&-1 {
				padding-bottom: $spacing-default;
			}
			&-2 {
				padding-bottom: $spacing-default * 2;
			}
			&-1_5 {
				padding-bottom: $spacing-default * 1.5;
			}
		}
	}
}
.pt {
	padding-top: math.div($spacing-default, 2);

	&-0 {
		padding-top: 0;
	}
	&-1 {
		padding-top: $spacing-default;
	}
	&-2 {
		padding-top: $spacing-default * 2;
	}
	&-1_5 {
		padding-top: $spacing-default * 1.5;
	}

	@include bp(sm) {
		&-sm {
			padding-top: math.div($spacing-default, 2);

			&-0 {
				padding-top: 0;
			}
			&-1 {
				padding-top: $spacing-default;
			}
			&-2 {
				padding-top: $spacing-default * 2;
			}
			&-1_5 {
				padding-top: $spacing-default * 1.5;
			}
		}
	}
}

.content-link {
	@include content-link;
}

.content-link--nocolor {
	@include content-link-nocolor;
}

.arrow-link {
	display: inline-flex;
	align-items: center;

	&::before {
		content: "";
		display: inline-block;
		width: 16px;
		height: 16px;
		min-width: 16px;
		margin-right: math.div($spacing-default, 2); // spacing over to the text
		background-color: currentColor;
		-webkit-mask-image: url(#{$icon-arrow-right});
		mask-image: url(#{$icon-arrow-right});
		transform-origin: center left;
	}

	// used for footer / leftmenu / link lists
	&--small {
		&::before {
			transform: scale(0.85);
			margin-right: 7px;
		}
		&::after {
			transform: scale(0.85);
			margin-left: 7px;
		}
	}
	// used on some modules
	&--big {
		&::before {
			transform: scale(1.25);
			margin-right: 13px;
		}
	}

	&--rotated-small {
		&::before {
			transform: rotate(180deg) scale(0.85);
			transform-origin: center center;
		}
	}

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	&--cta {
		color: var(--module-cta);
		font-weight: bold;
	}

	&--external {
		&::before {
			display: none;
		}
		&::after {
			content: "";
			display: inline-block;
			width: 16px;
			height: 16px;
			min-width: 16px;
			margin-left: math.div($spacing-default, 2); // spacing over to the text
			background-color: currentColor;
			-webkit-mask-image: url(#{$icon-external});
			mask-image: url(#{$icon-external});
			transform-origin: center left;
		}
	}
}
// in case the hover effect needs to be applied when hovering a containing element
.arrow-link-hover-wrapper {
	&:hover,
	&:focus {
		.arrow-link {
			text-decoration: underline;
		}
	}
}

.bg {
	&-white {
		background-color: $white;
	}
}

.w {
	&-100 {
		width: 100%;
	}
}
.h {
	&-100 {
		height: 100%;
	}
}
.mw-100{
	max-width: 100%;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;

	&-focusable {
		&:focus {
			position: static;
			width: auto;
			height: auto;
			overflow: visible;
			clip: auto;
			white-space: normal;
			clip-path: none;
		}
	}
}
.skip-link {
	height: 1px;
	overflow: hidden;
	position: absolute;
	top: -9999em;
	left: -9999em;
	display: inline-block;
	padding: 2em;
	font-size: 1.1rem;
	font-weight: 700;
	line-height: 0;
	color: $white;
	text-decoration: none;
	text-transform: none;
	box-shadow: 0 0 1px 1px rgb(0, 0, 0, 0.2);
	outline: 0;

	&:focus {
		left: $spacing-default;
		top: $spacing-default;
		z-index: 50000;
		width: auto;
		height: auto;
		clip: auto;
		color: $white;
		background-color: var(--primary);
	}
}

.loader {
	&::before {
		content: "";
		display: block;
		width: 1.5em;
		height: 1.5em;
		border: 3px solid $grey;
		border-top-color: $grey-dark;
		border-radius: 50%;
		animation: loaderspin 0.6s linear infinite;
	}

	@keyframes loaderspin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.module-m {
	margin: $spacing-large 0;
	@include bp(sm) {
		margin: $spacing-default * 1.5 0;
	}

	&--top {
		margin-top: $spacing-large;
		@include bp(sm) {
			margin-top: $spacing-default * 1.5;
		}
	}
}

.module-rte-m {
    margin: $spacing-default 0 $spacing-large;
    @include bp(sm) {
        margin: $spacing-default 0;
    }
 }
 
.img-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;

	&-video {
		@extend .img-cover;
		position: absolute;
		top: 0;
		left: 0;
	}
}

/* MODULE BACKGROUND POSITION */
.module-background-top-left {
	background-position: top left;
}

.module-background-top-center {
	background-position: top center;
}

.module-background-top-right {
	background-position: top right;
}

.module-background-center-left {
	background-position: center left;
}

.module-background-center-center {
	background-position: center center;
}

.module-background-center-right {
	background-position: center right;
}

.module-background-bottom-left {
	background-position: bottom left;
}

.module-background-bottom-center {
	background-position: bottom center;
}

.module-background-bottom-right {
	background-position: bottom right;
}

/* MODULE BACKGROUND SIZE (BEHAVIOUR) */
.module-background-size-normal {
	background-size: auto;
}

.module-background-size-cover {
	background-size: cover;
}

.module-background-size-contain {
	background-size: contain;
}

.noformat {
	.text-box-module__box,
	.link-list-module__box,
	.job-list-module__box,
	.campaign-box__inner,
	.campaign-box__content {
		box-shadow: none;
		background: none;
	}

	.link-list-module__inner,
	.text-box-module__inner,
	.job-list-module__inner {
		padding: 0;
	}
}

.ratio-wrapper {
	height: 0;
	position: relative;

	&--16-9 {
		padding-top: $ratio-16-9;
	}

	.ratio-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// K7
.noborder {
	border: none;

	td {
		border: none !important;
	}
	
	tr {
		border: none !important;
	}
}