@use "sass:math";
@import "tab-menu";
@import "service-menu";
@import "logo";
@import "nav-menu";
@import "search";
@import "header-large";
@import "header-small";

.header {
	&__large,
	&__small {
		border-bottom: $spacing-default * 0.4 solid $color-blue-dark;
	}
	&__large {
		display: block;
		@include bp($navigation-breakpoint) {
			display: none;
		}
	}
	&__small {
		display: none;
		@include bp($navigation-breakpoint) {
			display: block;
		}
	}
}
