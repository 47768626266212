.job-list-module {
	&__box {
		background-color: var(--module-primary);
		color: var(--module-primary-text);
		box-shadow: $box-shadow-base;
	}
	&__media {
		height: $height-small;
	}
	&__inner {
		padding: $spacing-default * 1.5 $spacing-default * 2;
		@include bp(xs) {
			padding: $spacing-default * 1.5;
		}
	}
	&__links {
		&--style {
			> li {
				margin-left: $spacing-default * 0.75;
				&::before {
					content: "·";
					font-size: var(--font-sm);
					font-weight: bold;
					position: absolute;
					margin-left: -($spacing-default * 0.75);
				}
			}
			.arrow-link:before {
				display: none;
			}
		}
	}
	&__link {
		@include bp(sm) {
			margin-bottom: $spacing-default;
		}
	}
}
