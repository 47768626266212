@use "sass:math";

.department-article-hero {
	background-color: $grey-cold;
	color: var(--module-primary-text);
	margin-bottom: $spacing-large;
	background-repeat: no-repeat;

	@include bp(sm) {
		margin-bottom: $spacing-default;
	}

	h1 {
		word-break: break-word;
	}

	.image-container {
		margin-bottom: $spacing-large * 2;
		line-height: 0;

		img {
			margin-bottom: -$spacing-large;
			width: 100%;
			height: auto;
		}

		@include bp(sm) {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: $spacing-default * 2;

			img {
				margin-bottom: -$spacing-default;
			}
		}
	}
}

.article-link-list {
	.link-col {
		@include bp(sm) {
			padding-bottom: 0;

			&:not(:last-child) {
				.btn {
					border-bottom: 1px solid $grey-border;
				}
			}
		}
	}
	.btn {
		text-align: left;

		@include bp(sm) {
			background-color: transparent;
			color: var(--module-cta);
			padding-top: $spacing-default;
			padding-bottom: $spacing-default;

			&:hover,
			&:focus {
				color: var(--module-cta);
				text-decoration: underline;
			}
		}
	}
}
