@use "sass:math";

.btn {
	font-weight: bold;
	display: inline-flex;
	align-items: center;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0;
	word-break: break-word;
	padding: 0.75em 1.15em; // uses em so it adjusts when using different font-size classes. e.g. .font-sm
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	@media print {
		align-items: flex-start;
	}

	&:hover,
	&:focus {
		background-color: $white;
		color: $black;
		text-decoration: none;
	}

	&:focus {
		box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.15);
		outline: 0;
		&:not(:focus-visible) {
			box-shadow: none;
		}
	}

	&:disabled,
	&.disabled {
		pointer-events: none;
		opacity: 0.65;
	}

	// by default, if an icon is added to the btn, it will appear on the right
	.icon {
		height: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: math.div(
			$spacing-default,
			2
		); // default margin when icon is on the left side of the text

		// adjust margin if icon is placed on the right side of the text
		&:last-child {
			margin-right: math.div(-$spacing-default, 4);
			margin-left: math.div($spacing-default, 2);
		}

		svg {
			height: 100%;
			fill: currentColor;
		}
	}

	// add rounded corners
	&--rounded {
		border-radius: 100px;
	}

	// centers text
	&--center {
		justify-content: center;
	}

	// wide button
	&--wide {
		width: 100%;
		max-width: 400px;
	}

	// add primary colors
	&--cta {
		background-color: var(--module-cta-background);
		color: var(--module-cta-text);

		&:hover,
		&:focus {
			background-color: var(--module-cta-hover);
			color: var(--module-cta-hover-color);
		}

		&.btn--outline {
			background-color: transparent;
			color: var(--module-cta);
			border-color: var(--module-cta);

			&:hover,
			&:focus {
				background-color: var(--module-cta);
				color: var(--module-cta-text);
			}
		}
	}

	&--link {
		text-decoration: underline;
		color: var(--module-cta);

		&:hover,
		&:focus {
			text-decoration: none;
			background-color: transparent;
		}
	}

	&--external {
		padding-right: 0.9em;
		&::after {
			content: "";
			display: inline-block;
			width: 16px;
			height: 16px;
			min-width: 16px;
			margin-left: 0.9em; // spacing over to the text
			background-color: currentColor;
			-webkit-mask-image: url(#{$icon-external});
			mask-image: url(#{$icon-external});
			transform-origin: center left;
		}
	}

	&--shadow {
		box-shadow: $box-shadow-buttons;
	}

	&.arrow-link {
		padding-left: 0.9em;
		&::before {
			margin-right: 0.9em;
		}
	}
}
// in case the hover effect needs to be applied when hovering a containing element
.btn-hover-wrapper {
	&:hover,
	&:focus {
		.btn {
			background-color: $white;
			text-decoration: none;

			&--cta {
				background-color: var(--module-cta-dark);
			}

			&--link {
				background-color: transparent;
			}
		}
	}
}
