@mixin inline-ul {
	ul {
		display: flex;
	}
}
@mixin transition-all {
	transition: all 0.3s ease-in-out;
}
@mixin animateRight() {
	animation-name: animateRight;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
	animation-direction: forwards;

	@keyframes animateRight {
		0% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
		39% {
			transform: translate3d(50%, 0, 0);
			opacity: 0.2;
		}

		40% {
			transform: translate3d(-30%, 0, 0);
			opacity: 0;
		}

		100% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
	}
}

@mixin transition-bg-color {
	transition: all 0.3s ease-in-out;
}

@mixin content-link {
	color: var(--module-cta);
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
	&:focus-visible {
		outline: 1px solid var(--module-cta);
		outline-offset: 2px;
	}
}

@mixin content-link-nocolor {
	color: inherit;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
	&:focus-visible {
		outline: 1px solid currentColor;
		outline-offset: 2px;
	}
}

@mixin bp-min($breakpoint) {
	@media (min-width: calc(map-get($gl-mq-list, $breakpoint) + 1px)) {
		@content;
	}
}
