@use "sass:math";

.header-small {
	&__top-menu {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $spacing-default * 0.75 $spacing-default;
		background-color: $white;
		height: $header-height-responsive;
	}
	&__logo {
		height: 100%;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 1;

		a {
			max-width: 260px;
			height: 100%;
			display: flex;
			align-items: center;

			img {
				max-height: 2rem;
				width: auto;
				max-width: 260px;
				height: 100%;
			}

			@include bp(sm) {
				max-width: 200px;

				img {
					max-width: 200px;
				}
			}
		}
	}
	&__logo-container {
		display: flex;
		align-items: center;
		min-width: 300px;
		max-width: 450px;
		width: auto;
	}

	&__logo-name {
		font-weight: bold;
		font-size: 13px;
		position: relative;
		margin-left: $spacing-default/2;
		@include bp(sm) {
			max-width: 200px;
		}
	}

	&__line {
		margin-left: $spacing-default /2;
		width: 1px;
		min-height: 2rem;
		background-color: $black;
	}
	
	&__burger {
		line-height: 0;
		svg {
			width: 32px;
			height: 32px;

			path {
				stroke: $black;
			}
		}
	}

	// navigation
	&__panel {
		position: fixed;
		z-index: 22;
		width: 100%;
		height: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: $white;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		@include transition-all();

		&--show {
			height: 100%;
		}
	}
	&__top-box {
		background-color: $color-blue-dark;
		padding: $spacing-default * 0.75 $spacing-default;
		display: flex;
		flex-flow: row wrap;
	}

	&__search {
		flex: 1;
		display: flex;
		align-items: center;
	}
	&__search-close {
		line-height: 0;
		margin-right: 0;
		width: 0;
		visibility: hidden;
		opacity: 0;
		@include transition-all();

		svg {
			width: 32px;
			height: 32px;

			path {
				stroke: $white;
			}
		}

		&--showing {
			margin-right: $spacing-default;
			width: 32px;
			visibility: visible;
			opacity: 1;
		}
	}
	&__cludo-search-form {
		width: 100%;
	}
	.search_autocomplete {
		background-color: $color-blue-dark;
		color: $white;
		width: 100%;
		padding: $spacing-default;
		position: fixed;
		top: 83px;
		left: 0;
		overflow: auto;
		border-radius: 0;
		box-shadow: none;
		max-height: calc(100vh - 82px);

		li {
			padding-left: $spacing-default * 1.5;
			&::before {
				left: 0;
			}
		}
	}
	.search-overlay {
		height: 100vh;
		width: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background: 0 0;
		visibility: visible;
		background-color: $color-blue-dark;
		transition: opacity 0.2s ease-out, visibility 0.2s ease-out;

		&.invisible {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
		}
	}

	.search-form,
	.header-small__search-close,
	.search-overlay,
	.header-small__close {
		z-index: 1;
	}

	&__close {
		line-height: 0;
		margin-left: $spacing-default;

		svg {
			width: 32px;
			height: 32px;

			path {
				stroke: $white;
			}
		}

		&--search-disabled {
			margin-left: auto;
		}
	}

	&__back-wrapper {
		display: block;
		width: 100%;
		margin-top: $spacing-default;
		color: $white;
		padding-inline: 5px;
		&--hide {
			display: none;
		}
	}
	&__back {
		transition: opacity 0.2s ease-out, visibility 0.2s ease-out;

		&--hide {
			opacity: 0;
			visibility: hidden;
		}
	}

	&__navigations {
		overflow: auto;
		flex: 1;
		display: flex;
		flex-direction: column;

		.nav-menu {
			height: auto;

			&__link {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;

				&--has-children {
					&::after {
						content: "";
						display: inline-block;
						width: 16px;
						height: 16px;
						min-width: 16px;
						margin-left: math.div(
							$spacing-default,
							2
						); // spacing over to the text
						background-color: currentColor;
						-webkit-mask-image: url(#{$icon-arrow-right});
						mask-image: url(#{$icon-arrow-right});
						transform-origin: center right;
						transform: scale(0.85);
					}
				}

				&[aria-current="page"] {
					color: var(--primary);
				}
			}

			&__item {
				@keyframes showItem {
					0% {
						opacity: 0;
						transform: translateY(10px);
					}
					100% {
						opacity: 1;
						transform: translateY(0);
					}
				}

				animation: showItem 0.2s ease-out forwards;
			}
		}
		.service-menu {
			margin-top: auto;
			transition: opacity 0.2s ease-out, visibility 0.2s ease-out;

			&--hidden {
				opacity: 0;
				visibility: hidden;
			}
		}
		.tab-menu {
			&__item {
				&:not(:first-child) {
					text-align: center;
					border-left: 1px solid $grey-border;
				}
			}
			&__link {
				width: 100%;
				&.active {
					border-color: transparent;
				}
			}

			&__list {
				overflow: hidden;
				align-items: center;
				height: 54px; // Used for animation. Works with tab titles of 1 and to lines of length
				transition: opacity 0.2s ease-out, visibility 0.2s ease-out,
					height 0.2s ease-out;
			}
			&--hidden {
				.tab-menu__list {
					height: 0;
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}
}
