@use "sass:math";

.breadcrumb {
	&__list {
		display: flex;
		flex-flow: row wrap;
		margin-right: math.div($spacing-default, 2);
		margin-top: math.div(-$spacing-default, 2);
	}
	&__item {
		margin-right: $spacing-default * 0.3;
		margin-top: math.div($spacing-default, 2);
		&:last-of-type {
			.icon {
				display: none;
			}
		}

		@include bp(sm) {
			display: none;
		}

		&--mobile {
			display: none;
			@include bp(sm) {
				display: block;
			}
		}
	}
	&__link {
		color: inherit;
		display: flex;
		align-items: center;
		line-height: 1;
		&:hover,
		&.focus-visible {
			text-decoration: underline;
			svg {
				@include animateRight;
			}
		}
		.icon {
			position: relative;
			height: $spacing-default * 0.4;
			white-space: normal;
			top: math.div(-$spacing-default, 4);
			display: inline-block;
			width: math.div($spacing-default, 5);
			margin: 0 math.div($spacing-default, 4);

			svg {
				max-height: 100%;
				width: math.div($spacing-default, 2);
				fill: currentColor;
			}
		}

		@media print {
			display: block;

			.icon {
				display: inline-block;
				transform: translate(0, 50%);
			}
		}
	}

	&--share-functions {
		display: flex;
		justify-content: space-between;
	}
}
