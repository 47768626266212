@use "sass:math";

.iframe-module {
	&__inner {
		padding: 0 $spacing-default*2.5;
		@include bp(md) {
			padding: 0 $spacing-default * 1.5;
		}
	}
	&__headline, &__summary {
		color: var(--module-primary-text);
	}
	&__summary {
		padding-bottom: $spacing-default/2;
	}

	&__iframe-content {
		padding: $spacing-default * 1.5 0;
		position: relative;
		width: 100%;
		padding-top: calc(55% * 1083 / 1080); /* Set height as percentage of width */
		overflow: hidden;
		
		@include bp(sm) {}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	&.zone {
		&--contentpage { 
			margin-inline: 0;
            padding-inline: 0;
			@include bp(sm) {
				margin-inline: -$spacing-default;
				padding-inline: 0;
			}
		}
	}
}

/* inside content page */
.grid-noGutter {
	.iframe-module {
		&__inner {
			padding: 0 $spacing-default/2;
		}
		&.zone {
			&--contentpage {
				margin-inline: -$spacing-default * 2;
				padding-inline: $spacing-default;
				@include bp(sm) {
					margin-inline: -$spacing-default;
					padding-inline: 0;
				}
			}
		}
	}
}

