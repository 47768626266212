//@use 'sass:math';

.list-overview-module {
	background-color: var(--module-background);

	&--contentpage {
		margin-inline: -$spacing-default * 2;
		padding-inline: $spacing-default * 2;
		@include bp(sm) {
			margin-inline: -$spacing-default;
			padding-inline: $spacing-default;
		}
	}
	&--with-bg {
		padding-block: $spacing-default;
		
		&.list-overview-module--contentpage {
			padding-block: $spacing-default * 2;
			@include bp(sm) {
				padding-block: $spacing-default;
			}
		}
	}

	&__top {
		color: var(--module-background-text);
	}

	&__box {
		background-color: var(--module-primary);
		color: var(--module-primary-text);
		padding: $spacing-default * 2;
		height: 100%;
		box-shadow: $box-shadow-base;
		
		@media print {
			padding: 0 !important;
		}
		
		@include transition-bg-color;

		@include bp(xs) {
			padding: $spacing-default * 1.5;
		}
		&--link {
			&:hover,
			&:focus,
			&:active {
				background-color: var(--module-cta-dark);
				color: var(--module-cta-text);
			}
		}
	}
	&__link {
		@include bp(sm) {
			margin-bottom: $spacing-default;
		}
	}
}
