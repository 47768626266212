@use "sass:math";

.quote {
	padding: $spacing-default * 3.5;

	&-container {
		background-color: $white;
		height: 100%;
		min-height: 350px;
		display: flex;
		align-items: center;
		justify-content: center;

		.swiper-slide[style*="height"] & {
			// don't set a min-height if a fixed height has been set on the slider
			min-height: auto;
		}
	}

	@include bp(sm) {
		padding: $spacing-default;
	}
	blockquote {
		font-style: italic;
		text-align: center;
		color: var(--module-cta);
	}
	q {
		font-style: italic;
		&::before {
			content: open-quote;
		}
		&::after {
			content: close-quote;
		}
	}
	figcaption {
		text-align: right;
		font-weight: bold;
		margin-top: math.div($spacing-default, 2);
		&::before {
			content: "\2014";
			padding-right: math.div($spacing-default, 4);
		}
	}
}
