@use "sass:math";

.subscription-module {
	&__inner {
		padding: $spacing-default * 1.5;
		background-color: var(--module-primary);
		box-shadow: $box-shadow-base;
		color: var(--module-primary-text);
	}
	&__form {
		margin: $spacing-default 0;
		position: relative;

		&.submitted {
			input {
				box-shadow: none !important;
			}
		}
	}
	&__headline {
		margin-bottom: $spacing-default * 0.75;
	}

	&__message {
		position: absolute;
		display: none;
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		text-align: center;
		padding: math.div($spacing-default, 2);
		background-color: var(--module-primary);
		&--active {
			display: block;
		}
	}
}
