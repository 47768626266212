@use "sass:math";

// DESKTOP
.header-search {
	&__search-btn {
		line-height: 0;

		svg {
			width: $icon-normal;
			height: $icon-normal;
		}
		.icon-close {
			display: none;
		}
	}

	&__cludo-search-form-wrapper {
		display: none;
		position: absolute;
		top: 50%;
		right: 40px;
		transform: translateY(-50%);
		background-color: $white;
		z-index: 22;

		@keyframes searchOpenDesktop {
			0% {
				width: 50%;
				opacity: 0;
			}
			100% {
				width: 100%;
				opacity: 1;
			}
		}
		animation: searchOpenDesktop 0.2s ease-out forwards;
	}

	&--open {
		.header-search {
			&__search-btn {
				.icon-close {
					display: block;
				}
				.icon-search {
					display: none;
				}
			}

			&__cludo-search-form-wrapper {
				display: block;
			}
		}
	}
}

// actual search input used for mobile and desktop
.search-form {
	position: relative;
	width: 100%;

	[type="search"]::-webkit-search-cancel-button,
	[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	&__input {
		outline: 0;
		border-radius: 4rem;
		padding: $spacing-default * 0.75 $spacing-default;
		padding-right: 60px;
		width: 100%;
		// padding: 1em 3em 1em 3.5em;
		font-size: 1em;
		font-weight: 400;
		background-color: $white;
		border: 2px solid transparent;
		box-shadow: $box-shadow-buttons;
		@include transition-all();

		&:focus {
			border-color: $grey-dark;
		}

		&::placeholder {
			// font-weight: bold;
		}
	}
	// search magnifier icon button
	&__submit {
		border: 0;
		line-height: 0;
		position: absolute;
		display: block;
		right: 1em;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 50%;
		background-color: transparent;
		padding: 8px;

		&:hover,
		&:focus {
			background-color: $grey-dark;
		}

		svg {
			width: 20px;
			height: 20px;

			path {
				stroke: currentColor;
			}
		}
	}

	&--flipped {
		.search-form__input {
			padding-right: $spacing-default;
			padding-left: 60px;
		}

		.search-form__submit {
			right: auto;
			left: 1em;
		}
	}

	&--big {
		.search-form__input {
			@extend .font-sm;
			@include bp(md) {
				font-size: inherit;
			}
		}
		@include bp-min(md) {
			.search-form__input {
				@extend .font-sm;
				padding: $spacing-default $spacing-default * 1.5;
				padding-right: 60px;
			}

			.search-form__submit {
				padding: 14px;
				right: 0.75em;
				svg {
					width: $icon-normal;
					height: $icon-normal;
				}
			}

			&.search-form--flipped {
				.search-form__input {
					padding-right: $spacing-default * 1.5;
					padding-left: 70px;
				}
				.search-form__submit {
					right: auto;
					left: 0.75em;
				}
			}
		}
	}
}

// set CludoSearch.debug = true on browsers console to easily style the dropdown
.search_autocomplete {
	position: absolute;
	display: block;
	background-color: $white;
	top: calc(100% + math.div($spacing-default, 2));
	left: 0;
	width: 100%;
	border-radius: 2rem;
	box-shadow: $box-shadow-buttons;
	padding: $spacing-default 0;
	list-style-type: none;
	z-index: 999;

	li {
		padding: math.div($spacing-default, 2) $spacing-default;
		width: 100%;
		position: relative;
		cursor: pointer;
		@extend .arrow-link;
		@extend .arrow-link--small;
		display: block;
		padding-left: 50px;

		&::before {
			position: absolute;
			left: $spacing-default;
			top: calc(50% - 8px);
		}

		b {
			display: inline;
			font-weight: bold;
		}

		&:hover,
		&.active {
			text-decoration: underline;
		}
	}
}
