@use "sass:math";

.hero-search {
	position: relative;

	&__bg-img {
		width: 100%;
		object-fit: cover;
		z-index: 2;
	}

	&__img {
		position: absolute;
		width: 45%;
		right: 0;
		top: 0;
		height: 100%;
		z-index: 3;
		@include bp(sm) {
			width: 100%;
			top: 0;
			height: 50%;
		}
	}

	&__overlay,
	&__bg-img > img {
		position: absolute;
		width: 100%;
		height: 100%;
		background: $black;
		top: 0;
		left: 0;
		opacity: 0.5;
		z-index: 3;

		&.img-cover--small {
			display: none;
			@include bp(sm) {
				display: block;
			}
		}
		&.img-cover--large {
			@include bp(sm) {
				display: none;
			}
		}
	}

	&__bg-img--no-overlay {
		> img {
			opacity: 1;
		}
		.hero-search__overlay {
			opacity: 0;
		}
	}

	&__inner {
		background-color: var(--module-primary);
		background-repeat: no-repeat;
		position: relative;
		height: 550px;
		width: 100%;
		&--custom {
			background-color: transparent;
		}

		@include bp(sm) {
			height: 55vh;
		}
	}

	&__content {
		z-index: 4;
		position: absolute;
		bottom: 15%;
		width: 40%;
		left: $spacing-default * 2;
		color: var(--module-primary-text);
		@include bp(sm) {
			bottom: $spacing-default * 2.5;
			width: calc(100% - ($spacing-default * 3));
			left: $spacing-default * 1.5;
		}
	}

	&__headline {
		margin-left: $spacing-default;
		@include bp(sm) {
			margin-left: math.div($spacing-default, 4);
		}
	}
}
