﻿.print-button {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: $spacing-default/2;
  display: none;
  
  &:hover, &:focus {
    opacity: .8;
  }
  
  &--align-right {
    margin-left: auto;
  }
}