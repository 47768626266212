.header-large {
	&__top-menu {
		background-color: $color-blue-dark;
		border-bottom: $grey-border 1px solid;
		color: $white;
	}
	&__top-menu-container {
		display: grid;
		grid-template-areas: "tabmenu servicemenu";
		padding-right: $spacing-default * 2;
		@include bp($navigation-breakpoint) {
			padding: 0;
		}
	}
	&__menu {
		background-color: $white;
	}
	&__menu-container {
		display: grid;
		grid-template-areas: "logo mainmenu";
		padding: 0 $spacing-default * 2;
		@include bp(sm) {
			padding: 0;
		}
	}
}
