@use "sass:math";

.radio-facet {
	input[type="radio"] {
		appearance: none;
		margin: 0;

		&:focus {
			+ label {
				box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.15);
				outline: 0;
			}
		}

		&:focus,
		&:checked {
			+ label {
				background-color: var(--module-cta);
				color: var(--module-cta-text);
			}
		}
	}
	label {
		@extend .btn;
		@extend .btn--cta;
		@extend .btn--outline;
		// @extend .btn--rounded;
		padding: 0.475em 0.75em;
		border-radius: $border-radius-base;
		text-align: left;
	}

	margin-right: math.div($spacing-default, 2);
	margin-bottom: math.div($spacing-default, 2);

	&-wrapper {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
	}
}

.ydelse-facets {
	@include bp(sm) {
		padding: 0;
	}

	&__toggle-more {
		padding: 0.475em 0.75em;
	}
}

.list-loader {
	display: flex;
	justify-content: center;
	padding: 50px 0;
}
