@use "sass:math";

.text-box-module {
	&__box {
		height: 100%;
		background-color: var(--module-primary);
		color: var(--module-primary-text);
		box-shadow: $box-shadow-base;

		&--white {
			background-color: $white;
			color: inherit;
		}
	}
	&__tag {
		margin-bottom: math.div($spacing-default, 4);
	}
	&__media {
		width: 100%;
		height: 0;
		position: relative;
		padding-bottom: $ratio-16-9;
	}
	&__inner {
		padding: $spacing-default * 1.5 $spacing-default * 2;
		@include bp(xs) {
			padding: $spacing-default * 1.5;
		}
	}
	&__link {
		padding: $spacing-default $spacing-default * 1.5;
		margin-top: $spacing-default * 1.5;
		@include bp(sm) {
			padding: math.div($spacing-default, 2) $spacing-default;
		}
	}
	&__link-item {
		display: block;
	}
	&--centered {
		margin: 0 auto;
	}
}
