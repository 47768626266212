﻿@use "sass:math";

.share-functions {
	position: relative;
	&__button {
		display: flex;
		> span {
			display: inline-block;
			width: $icon-large;
			height: $icon-large;
			text-align: center;
			border-radius: 50%;
			line-height: 1.25;
			color: $white;
		}
		img {
			border-radius: 50%;
		}
		&:hover, &:focus {
			opacity: .8;
		}
	}

	&__list {
		display: none;
		position: absolute;
		background: $white;
		min-width: calc($spacing-large * 5);
		right: 0;
		padding: math.div($spacing-default, 2.5);
		border: 1px solid $grey-border;
		margin-top: math.div($spacing-default, 4);
		z-index: 2;
	}
	&__item {
		background-color: var(--module-primary);
		padding: math.div($spacing-default, 4) math.div($spacing-default, 2);

		&:not(:last-of-type) {
			margin-bottom: math.div($spacing-default, 10);
		}
	}

	&__inner {
		display: flex;
		align-items: baseline;
	}
	&__link {
		display: flex;
		> span {
			display: inline-block;
		}
	}
	&__text {
		text-transform: uppercase;
		align-self: center;
	}
	&__icon {
		width: $icon-large;
		height: $icon-large;
		background-size: cover;
		background-position: center;
		line-height: 1.25;
		margin-right: math.div($spacing-default, 2);
		margin-right: math.div($spacing-default, 2);
	}
}
