@use "sass:math";

.contact {
	margin-left: -$spacing-default * 2;
	margin-right: -$spacing-default * 2;
	@include bp(sm) {
		margin-left: -$spacing-default;
		margin-right: -$spacing-default;
	}

	&__content {
		padding: $spacing-default * 2;

		@include bp(sm) {
			padding: $spacing-default;
		}
	}

	&__map {
		&-copyright {
			padding: math.div($spacing-default, 2) $spacing-default;
			text-align: right;
		}
	}

	&--has-theme {
		background-color: var(--module-primary, #{$white});
		color: var(--module-primary-text);

		.contact-column__icon {
			.icon-mappin path {
				stroke: var(--module-primary) !important;
			}
			.icon-speechbubbles path {
				fill: var(--module-primary) !important;
			}
		}
	}
}

.contact-column {
	display: flex;

	&__icon {
		min-width: 40px;
		width: 40px;
		height: 40px;
		background-color: var(--module-primary-text, var(--module-primary));
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: math.div($spacing-default, 2);

		@include bp(sm) {
			min-width: 30px;
			width: 30px;
			height: 30px;
		}

		.icon {
			width: $icon-normal - 5px;
			display: flex;
			align-items: center;
			justify-content: center;

			@include bp(sm) {
				width: $icon-small;
			}
		}
	}

	&__content {
		flex: 1;
	}
}

.contact-wide {

	padding: 40px 0 20px 0;

	.contact__map {
		padding-top: $spacing-default * 2;
		@include bp(sm) {
			padding-top: 0;
		}
	}
	&--has-theme {
		background-color: var(--module-primary);
		color: var(--module-primary-text);

		.contact-column__icon {
			.icon-mappin path {
				stroke: var(--module-primary) !important;
			}
			.icon-speechbubbles path {
				fill: var(--module-primary) !important;
			}
		}
	}
}