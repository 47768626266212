// GENERIC COLORS
$white: #fff;
$black: #000;

$grey: #f5f3f4;
$grey-border: #e8e5e7;
$grey-dark: #bdbec2;
$grey-cold: #dce1e7;
$grey-warm: #f5f3f4;

$color-focus-yellow: #E7BC40;
$color-blue-dark: #002138;