.footer__sign-language {
	width: 110px;
	min-width: 110px;
	height: 40px;
	min-height: 40px;
	position: relative;
	display: inline-block;
	font-size: 0.85em;

	input[type="checkbox"] {
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;

		&:focus {
			+ .switch-slider {
				box-shadow: 0 0 0 2px $grey-cold, 0 0 0 4px rgba(0, 0, 0, 0.1);
			}
		}
	}

	.switch-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.1);
		transition: 0.2s;
		border-radius: 24px;

		&::before {
			position: absolute;
			content: "";
			left: 2px;
			bottom: 2px;
			transition: 0.2s;
			width: 56px;
			height: 36px;
			border-radius: 100px;
			background-color: $white;
		}

		&__label {
			z-index: 1;
			width: 55px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			position: absolute;
			top: 0;
			transition: opacity 0.15s ease-in-out;
			user-select: none;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 0 2px;
			text-transform: uppercase;
			font-weight: bold;

			&--true {
				color: var(--primary-text);
				right: 2px;
				opacity: 0;
			}
			&--false {
				left: 2px;
				opacity: 1;
			}
		}
	}

	input:checked {
		+ .switch-slider {
			&::before {
				transform: translateX(50px);
				background-color: $color-blue-dark;

			}

			.switch-slider__label {
				&--true {
					opacity: 1;
					color: $white;
				}
				&--false {
					opacity: 0;
				}
			}
		}
	}
}
